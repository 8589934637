import { defaultLocale } from "./i18n";
import { currentLocale } from "./locale-provider";
import { removeLocaleFromSlug } from "./remove-locale-from-slug";

const useLocalizedData = (data) => {
  if (!data?.nodes?.length) {
    return data;
  }
  let localeData = data.nodes.filter((node) => node.locale === currentLocale);
  // Fallback to standard locale
  if (!localeData) {
    localeData = data.nodes.filter((node) => node.locale === defaultLocale);
  }
  // Collection
  if (localeData.length > 1) {
    localeData.forEach((node) => {
      if (node.slug) {
        node.slug = removeLocaleFromSlug(node.slug);
      }
    });
    return localeData;
  }
  // Single Type
  return localeData[0];
};

export default useLocalizedData;
