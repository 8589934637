import React from "react";
import { Layout } from "./src/components/layout/layout";
import { LocaleProvider } from "./src/i18n/locale-provider";

export const wrapPageElement = ({ element, props }) => {
  return (
    <LocaleProvider {...props}>
      <Layout>{element}</Layout>
    </LocaleProvider>
  );
};
