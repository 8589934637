import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import {
  activeLink,
  open,
  themeSwitch,
  slider,
  sliderBall,
  nav,
  navItem,
} from "./navigation.module.scss";
import darkModeIcon from "../../assets/icons/dark-mode.svg";
import lightModeIcon from "../../assets/icons/light-mode.svg";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { LocaleContext } from "../../i18n/locale-provider";
import { prependLocale } from "../../i18n/prepend-locale";
import { GetNavigationItems } from "../../queries/getNav";

const Navigation = ({ isOpen, close, className }) => {
  const links = GetNavigationItems();
  const location = useLocation();
  const { locale } = useContext(LocaleContext);
  const [darkModeActive, setDarkModeActive] = useState(false);

  const onNavClick = () => {
    close();
  };

  const onAppearenceClick = (event) => {
    event.stopPropagation();
  };

  const changeAppearence = (theme) => {
    if (!theme) {
      return;
    }
    const classList = document.documentElement.classList;
    setDarkModeActive(theme === "dark");
    classList.remove("dark");
    classList.remove("light");
    classList.add(theme);
    localStorage.setItem("csite-theme", theme);
  };

  const onAppearenceChange = (event) => {
    changeAppearence(event.target.checked ? "dark" : "light");
  };

  useEffect(() => {
    let theme = localStorage.getItem("csite-theme") ?? null;
    if (window.matchMedia && !theme) {
      theme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    }
    if (theme) {
      changeAppearence(theme);
    }
  }, []);

  return (
    <nav
      className={`${nav} ${isOpen ? `${open}` : ""} ${className}`}
      onClick={() => onNavClick()}
      onKeyDown={() => close()}
    >
      {links.map((link) => (
        <Link
          activeClassName="text-gray-900 dark:text-white"
          key={link.path}
          to={prependLocale(link.path)}
          className={`${navItem} text-gray-600 dark:text-gray-400 lg:hover:text-gray-900 dark:lg:hover:text-white`}
        >
          {link.name}
        </Link>
      ))}
      <div className="mb-[var(--page-border)] mt-auto flex flex-col gap-4 lg:mb-0 lg:mt-0 lg:flex-row">
        <div
          className={`flex items-center justify-between rounded-lg bg-gray-200 px-4 py-2 dark:bg-gray-800 lg:bg-transparent lg:dark:bg-transparent`}
        >
          <p className="inline-block text-sm lg:hidden">
            Design: {darkModeActive ? "Dunkel" : "Hell"}
          </p>
          <label
            className={themeSwitch}
            htmlFor="checkbox"
            onClick={onAppearenceClick}
          >
            <input
              type="checkbox"
              id="checkbox"
              onChange={onAppearenceChange}
              checked={darkModeActive ?? false}
            />
            <div className={slider}>
              <div className={sliderBall}>
                <img
                  src={darkModeActive ? darkModeIcon : lightModeIcon}
                  alt="Theme switch icon"
                />
              </div>
            </div>
          </label>
        </div>
        <div className="mx-auto flex items-center gap-4 lg:ml-4">
          <Link
            className={`${locale === "de" ? "text-gray-600 dark:text-white" : "text-gray-400"} no-underline`}
            to={`${(location.pathname.replace(`/${locale}/`), "/")}`}
          >
            de
          </Link>
          <span className="text-gray-400">/</span>
          <Link
            className={`${locale === "en" ? "text-gray-600 dark:text-white" : "text-gray-400"} no-underline`}
            to={`/en${(location.pathname.replace(`/${locale}/`), "/")}`}
          >
            en
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
