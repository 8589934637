import { graphql, useStaticQuery } from "gatsby";
import useLocalizedData from "../i18n/use-localized-data";

export const GetNavigationItems = () => {
  const siteData = useStaticQuery(graphql`
    query getAllNav {
      allStrapiNavigation(sort: { position: ASC }) {
        nodes {
          locale
          name
          path
        }
      }
    }
  `);
  return useLocalizedData(siteData.allStrapiNavigation);
};
