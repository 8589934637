import React from "react";
import { defaultLocale } from "./i18n";

export let currentLocale = defaultLocale;
const LocaleContext = React.createContext();

const LocaleProvider = ({ children, pageContext: { locale } }) => {
  currentLocale = locale;
  return (
    <LocaleContext.Provider value={{ locale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export { LocaleProvider, LocaleContext };
