import React from "react";
import Header from "../header/header";
import { content } from "./layout.module.scss";
import ParallaxLogos from "../../animations/parallax-logos/parallax-logos";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className={`${content}`}>{children}</main>
      <ParallaxLogos />
    </>
  );
};

export { Layout };
