exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-data-protection-jsx": () => import("./../../../src/pages/data-protection.jsx" /* webpackChunkName: "component---src-pages-data-protection-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-index-jsx": () => import("./../../../src/pages/projects/index.jsx" /* webpackChunkName: "component---src-pages-projects-index-jsx" */),
  "component---src-pages-projects-strapi-project-slug-jsx": () => import("./../../../src/pages/projects/{strapiProject.slug}.jsx" /* webpackChunkName: "component---src-pages-projects-strapi-project-slug-jsx" */)
}

